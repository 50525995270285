import React from "react";
import TagCards from "./TagCards/TagCards";
import TransitionTable from "./TransitionTable/TransitionTable";
import WarehouseBarChart from "./WarehouseBarChart/WarehouseBarChart";
import "./DashBoard.css";

const DashBoard: React.FC = () => {
  return (
    <div className="main_container" style={{ margin: 10 }}>
      <TagCards />
      <TransitionTable />
    </div>
  );
};

export default DashBoard;
