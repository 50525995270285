import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-logo">
        <img src="images/SchemaX-white.png" alt="Logo" className="logo" />
      </div>
      <div className="navbar-links">
        <Link to="/">Dashboard</Link>
        <Link to="/items">Items</Link>
        <Link to="/workstation">Workstation</Link>
      </div>
    </div>
  );
};

export default Navbar;
