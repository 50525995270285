import React, { useEffect, useState, useRef } from "react";
import { message, Table, Button } from "antd";
import "./TransitionTable.css";
import axios from "axios";
import { backend_api } from "../../config";

type TransitionData = {
  id: number;
  warehouse: string;
  item: string;
  tag: string;
  status: string;
  tagName: string;
  transitionStatus: string;
};

const TransitionTable: React.FC = () => {
  const [transitionData, setTransitionData] = useState<TransitionData[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(
    null
  );
  const [currentWarehouseIndex, setCurrentWarehouseIndex] = useState<number>(0);

  const leftTableRef = useRef<HTMLDivElement>(null);
  const rightTableRef = useRef<HTMLDivElement>(null);
  const [rightTableMaxHeight, setRightTableMaxHeight] = useState<number>(0);

  useEffect(() => {
    fetchTransitionData();

    const intervalId = setInterval(() => {
      fetchTransitionData();
    }, 120000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchTransitionData = async () => {
    try {
      const response = await axios.post(
        `${backend_api}/transition-flow/getAllTransitionFlow`
      );

      if (response.data && response.data.data) {
        setTransitionData(response.data.data);
      } else {
        throw new Error("Invalid data format from API");
      }
    } catch (error: any) {
      message.error("Failed to fetch tags");
    } finally {
      setLoading(false);
    }
  };

  const groupedData = transitionData.reduce(
    (acc: { [key: string]: TransitionData[] }, currentItem) => {
      if (!acc[currentItem.warehouse]) {
        acc[currentItem.warehouse] = [];
      }
      acc[currentItem.warehouse].push(currentItem);
      return acc;
    },
    {}
  );

  const warehouseData = Object.keys(groupedData).map((warehouse, index) => ({
    key: index,
    warehouse,
    itemCount: groupedData[warehouse].length,
  }));

  // Automatically cycle through warehouses every minute
  useEffect(() => {
    const cycleWarehouse = () => {
      if (warehouseData.length > 0) {
        setCurrentWarehouseIndex(
          (prevIndex) => (prevIndex + 1) % warehouseData.length
        );
        setSelectedWarehouse(
          warehouseData[currentWarehouseIndex]?.warehouse || null
        );
      }
    };

    const intervalId = setInterval(cycleWarehouse, 10000); // 10 seconds

    // Set initial selected warehouse
    setSelectedWarehouse(
      warehouseData[currentWarehouseIndex]?.warehouse || null
    );

    return () => clearInterval(intervalId);
  }, [warehouseData, currentWarehouseIndex]);

  useEffect(() => {
    if (selectedWarehouse && groupedData[selectedWarehouse]) {
      const leftTableRows = warehouseData.length;
      const rightTableRows = groupedData[selectedWarehouse].length;

      if (leftTableRef.current) {
        const leftTableHeight = leftTableRef.current.offsetHeight;

        // Set rightTableMaxHeight to leftTableHeight if right table has fewer or equal rows; otherwise, use 0 (no scrolling).
        setRightTableMaxHeight(
          leftTableRows >= rightTableRows ? leftTableHeight : 0
        );
      }
    }
  }, [transitionData, selectedWarehouse, warehouseData]);

  const getRowClassName = (status: string) => {
    switch (status) {
      case "Present":
        return "row-present";
      case "Missing":
        return "row-missing";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "10%",
      align: "center" as const,
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      align: "left" as const,
    },
    {
      title: "Tag Name",
      dataIndex: "tagName",
      key: "tagName",
      align: "left" as const,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
    },
    {
      title: "Transition Status",
      dataIndex: "transitionStatus",
      key: "transitionStatus",
      align: "center" as const,
      render: (text: string) => {
        let gifSrc = "/images/forbidden.gif"; // Default GIF

        if (text === "Missing") {
          gifSrc = "/images/letterX.gif";
        } else if (text === "Present") {
          gifSrc = "/images/verified.gif";
        }

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {text}
            <img
              src={gifSrc}
              alt={
                text === "Missing"
                  ? "Missing"
                  : text === "Present"
                  ? "Default"
                  : "Default"
              }
              style={{
                width: "30px",
                height: "30px",
                marginLeft: "8px",
                backgroundColor: "transparent",
                border: "none",
                mixBlendMode: "multiply",
              }}
            />
          </div>
        );
      },
    },
  ];

  const warehouseColumns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse",
      key: "warehouse",
    },
    {
      title: "Items",
      dataIndex: "itemCount",
      key: "itemCount",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Button
          type="primary"
          onClick={() =>
            setSelectedWarehouse(
              selectedWarehouse === record.warehouse ? null : record.warehouse
            )
          }
        >
          {selectedWarehouse === record.warehouse ? "Hide" : "Show"}
        </Button>
      ),
    },
  ];

  return (
    <div className="dashboard-container">
      <div className="table-wrapper">
        {/* Left Table */}
        <div className="table-container left-table" ref={leftTableRef}>
          <Table
            columns={warehouseColumns}
            dataSource={warehouseData}
            pagination={false}
            rowKey="warehouse"
            loading={loading}
          />
        </div>

        {/* Right Table */}
        <div
          className="table-container right-table"
          ref={rightTableRef}
          style={{
            maxHeight:
              rightTableMaxHeight > 0 ? `${rightTableMaxHeight}px` : "auto",
            overflowY: rightTableMaxHeight > 0 ? "auto" : "unset",
          }}
        >
          {selectedWarehouse && (
            <Table
              columns={columns}
              dataSource={groupedData[selectedWarehouse]}
              rowClassName={(record) =>
                getRowClassName(record.transitionStatus)
              }
              pagination={false}
              rowKey="id"
              loading={loading}
              scroll={{
                y: 240,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransitionTable;
