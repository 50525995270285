import React, { useEffect, useState } from "react";
import { Row, Col, Card, message } from "antd";
import axios from "axios";
import "./TagCards.css";
import { backend_api } from "../../config";

// Dashboard component
const TagCards: React.FC = () => {
  const [tagData, setTagData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTags(); // Fetch data initially on mount

    // Set interval to refresh data every 2 minutes (120,000 milliseconds)
    const intervalId = setInterval(() => {
      fetchTags();
    }, 120000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Function to fetch tag data from API
  const fetchTags = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${backend_api}/tag/getAllTags`);
      console.log(response);
      if (response.data && response.data.data) {
        setTagData(response.data.data);
      } else {
        throw new Error("Invalid data format from API");
      }
    } catch (error: any) {
      setError(error.message || "Error fetching tags data");
      message.error("Failed to fetch tags");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  // Function to calculate the counts based on status
  const getCounts = (data: typeof tagData) => {
    const counts = {
      Open: 0,
      Inprogress: 0,
      Close: 0,
    };

    data.forEach((tag: any) => {
      if (tag.status === "Open") {
        counts.Open++;
      } else if (tag.status === "Inprogress") {
        counts.Inprogress++;
      } else if (tag.status === "Close") {
        counts.Close++;
      }
    });

    return counts;
  };

  const counts = getCounts(tagData);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"/images/rotate.gif"}
          alt={"loading....."}
          style={{
            width: "80px",
            height: "80px",
            marginLeft: "8px",
            backgroundColor: "transparent",
            border: "none",
            mixBlendMode: "multiply",
          }}
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Row gutter={16} className="tag-cards-container">
      <Col span={8}>
        <Card title="Open Tags" bordered={false} className="tag-card open-bg">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tag-count">{counts.Open}</p>
            <img
              src={"/images/open.gif"}
              alt={"Open tags"}
              style={{
                width: "60px",
                height: "60px",
                marginLeft: "8px",
                backgroundColor: "transparent",
                border: "none",
                mixBlendMode: "multiply",
              }}
            />
          </div>
        </Card>
      </Col>
      <Col span={8}>
        <Card
          title="Assigned Tags"
          bordered={false}
          className="tag-card inprogress-bg"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tag-count">{counts.Inprogress}</p>
            <img
              src={"/images/hold-on.gif"}
              alt={"Open tags"}
              style={{
                width: "60px",
                height: "60px",
                marginLeft: "8px",
                backgroundColor: "transparent",
                border: "none",
                mixBlendMode: "multiply",
              }}
            />
          </div>
        </Card>
      </Col>
      <Col span={8}>
        <Card
          title="Closed Tags"
          bordered={false}
          className="tag-card closed-bg"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="tag-count">{counts.Close}</p>
            <img
              src={"/images/closed.gif"}
              alt={"Open tags"}
              style={{
                width: "60px",
                height: "60px",
                marginLeft: "8px",
                backgroundColor: "transparent",
                border: "none",
                mixBlendMode: "multiply",
              }}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default TagCards;
