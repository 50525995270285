import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import DashBoard from "./dashBoardComponents/DashBoard";
import ItemForm from "./Item/ItemForm";
import WorkStationForm from "./WorkStation/WorkStationForm";

const MasterRoutes = () => {
  return (
    <HashRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="/items" element={<ItemForm />} />
        <Route path="/workstation" element={<WorkStationForm />} />
      </Routes>
    </HashRouter>
  );
};

export default MasterRoutes;
